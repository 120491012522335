import './Main.css';
// import QR from './Images/';


export function Frame4() {

    return (
        <>
            <div className="ff">
                <div className="ff1">
                    <div className="boxheads1">Have a question?</div>
                    <div className="boxheads2">Send Us Your Query</div>
                </div>
                <div className="ff2">
                    <div className="row">
                        <input type="text" id='fname' className='ftxt' placeholder='Name' />
                        <input type="email" name="femail" id="femail" className='ftxt' placeholder='Email' />
                    </div>
                    <div className="row">
                        <input type="text" id='fphone' className='ftxt' placeholder='Contact' />
                    </div>
                    <div className="row">
                        <textarea name="farea" id="farea" placeholder='Message'></textarea>
                    </div>
                    <div className="row">
                        <button className='bookbtn'>Submit</button>
                    </div>
                </div>
            </div>
            <div className="ff">
                <div className="ff1">
                    <div className="boxheads1">Or Book Your Stay by</div>
                    <div className="boxheads2">Scannning The QR</div>
                    <div className="boxheaddesc">Scan the QR code to easily make your reservation and start your desert adventure.</div>
                </div>
                <div className="ff2">
                    {/* <img src={QR} alt="" srcset="" /> */}
                </div>
            </div>
        </>
    )
}
