import './App.css';
import React, { useState, useEffect } from 'react';
import Navbar from './Components/Navbar';
import AdminNavbar from './Components/AdminNavbar';
import img1 from './Components/Images/img3.JPG';
import img2 from './Components/Images/img2.JPG';
import img3 from './Components/Images/img4.JPG';
import About from './Components/About';
import Desert from './Components/desert';
import Packages from './Components/Packages';
import Home from './Components/Home';
import Dashboard from './Components/Dashboard';
import AddReviews from './Components/AddReviews';
import Upackages from './Components/upackages';
import Login from './Components/Login';
import Contact from './Components/Contact';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  const [head1, setHead1] = useState('Enchanting and unforgettable');
  const [head2, setHead2] = useState('Desert Safari');
  const [src, setsrc] = useState(img1);
  const images = [img2, img3, img1];
  let currentIndex = 0;

  useEffect(() => {
    const interval1 = setInterval(() => {
      setHead1((prevHead1) => {
        if (prevHead1 === 'Enchanting and unforgettable') {
          return 'Indulgent and traditional';
        } else if (prevHead1 === 'Indulgent and traditional') {
          return 'Step back in time at';
        } else {
          return 'Enchanting and unforgettable';
        }
      });
    }, 2000);

    const interval2 = setInterval(() => {
      setHead2((prevHead2) => {
        if (prevHead2 === 'Desert Safari') {
          return 'Desert Camps';
        } else if (prevHead2 === 'Desert Camps') {
          return 'Forts & Ruins';
        } else {
          return 'Desert Safari';
        }
      });
    }, 2000);

    const interval3 = setInterval(() => {
      setsrc(() => {
        currentIndex = (currentIndex + 1) % images.length;
        return images[currentIndex];
      });
    }, 2000);

    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
      clearInterval(interval3);
    };
  }, []);

  return (
    <Router>
      <Routes>
        {/* Normal User Routes */}
        <Route exact path="/" element={<><Navbar /><Home head1={head1} head2={head2} src={src} /></>} />
        <Route exact path="/about" element={<><Navbar /><About /></>} />
        <Route exact path="/desert" element={<><Navbar /><Desert /></>} />
        <Route exact path="/packages" element={<><Navbar /><Packages /></>} />
        <Route exact path="/Contact" element={<><Navbar /><Contact /></>} />

        {/* Admin Routes */}
        <Route exact path="/admin" element={<Login />} />
        <Route exact path="/dashboard" element={<><AdminNavbar /><Dashboard /></>} />
        <Route exact path="/addreviews" element={<><AdminNavbar /><AddReviews /></>} />
        <Route exact path="/upackages" element={<><AdminNavbar /><Upackages /></>} />
      </Routes>
    </Router>
  );
}

export default App;
