import React from 'react';
import Frame1 from './Frame1';
import Frame2 from './Frame2';
import Frame3 from './Frame3';
import Frame4 from './Frame4';
import Frame5 from './Frame5';

const Home = ({ head1, head2, src }) => {
  return (
    <div>
      <Frame1 head1={head1} head2={head2} src={src} />
      <Frame2 />
      <Frame3 />
      <Frame4 />
      <Frame5 />
    </div>
  );
};

export default Home;
