import React from 'react';
// import Proptypes from 'prop-types';
// import { Link } from 'react-router-dom';
import './Main.css';



export default function Frame1(props) {
    return (
<>
<div id="frame1">
    <div id="fb1">
        <div id="head1">{props.head1}</div>
        <div id="head2">{props.head2}</div>
        <button id='expbtn'>Explore</button>
    </div>
    <div id="fb2"><img src={props.src} alt="" id='fb2img' className=''/></div>
</div>
</>
    )
}
