import React, { useState } from 'react';
import Slide1 from './Images/Slide1.png';
import Slide2 from './Images/Slide2.png';
// import Proptypes from 'prop-types';
// import { Link } from 'react-router-dom';
import './Main.css';



export default function Frame2() {
    const [f2b1b1head2, setf2b1b1head2] = useState('Unique Cozy Retreat');
    const [slide, setslide] = useState(Slide2);
    const handlenextClick = () => {
        setf2b1b1head2("Sand Dune Expedition")
        setslide(Slide1)
    }
    const handleprevClick = () => {
        setf2b1b1head2("Unique Cozy Retreat")
        setslide(Slide2)
    }

    return (
        <>
            <div id="frame2">
                <div id="f2b1">
                    <div id="f2b1b1">
                        <div className="boxheads1">Experience</div>
                        <div className='boxheads2'>{f2b1b1head2}</div>
                    </div>
                    <div className="boxheadsdesc">Experience luxury in a desert tent nestled among the sand dunes, enveloped in a peaceful and serene ambiance. The tent boasts plush bedding, sophisticated decor, and traditional Rajasthani elements, offering a cozy and genuine desert retreat.</div>
                </div>
                <div id="f2b2">
                    <div id="f2b2sliderbtnprev" className="f2b2sliderbtn" onClick={handleprevClick}>&larr;</div>
                    <div id="f2b2slider"><img src={slide} alt="" id='slide'/></div>
                    <div id="f2b2sliderbtnnext" className="f2b2sliderbtn" onClick={handlenextClick}>&rarr;</div>
                </div>
            </div>
        </>
    )
}
