import React from 'react';
import './Main.css';



export default function Frame1() {
    return (
        <>
            <div id="frame5">
                <div id="f5b1">
                    <div className="boxheads1">See</div>
                    <div className='boxheads2'>What Our Guests Are Saying</div>
                    <div className="boxheadsdesc">Hear Directly from Those Who’ve Stayed With Us</div>
                    <div id="forbtnflex">
                        <div id="f2b2sliderbtnprev" className="f2b2sliderbtn">&larr;</div>
                        <div id="f2b2sliderbtnnext" className="f2b2sliderbtn">&rarr;</div>
                    </div>
                </div>
                <div id="f5b2"></div>
            </div>
        </>
    )
}
