import React, { useState } from 'react';
import './Main.css';
import Slide1 from './Images/Slide1.png';
import Slide2 from './Images/Slide2.png';



export default function Frame1() {
    const [f2b1b1head2, setf2b1b1head2] = useState('Thrill of Off-Road Jeep Safari');
    const [f2b1b1desc, setf2b1b1desc] = useState(`The jeep safari is a thrilling adventure in the Thar Desert. Whether you choose to enjoy the sunrise or sunset safari, you'll witness breathtaking desert landscapes. Some tours also include a visit to traditional mud houses. Our 4x4 jeep safari offers a 35-minute ride across the dunes.`);
    const [slide, setslide] = useState(Slide2);
    const handlenextClick = () => {
        setf2b1b1head2("Desert's Magic on a Camel Safari")
        setf2b1b1desc(`One of India’s most memorable and enchanting experiences is riding a camel through the windswept desert and camping under the stars. A camel safari in Jaisalmer offers a unique chance to witness the traditional desert life of India. Explore the vast Great Indian Desert of Rajasthan on camelback, and immerse yourself in the rustic and regal charm of Rajasthan's sandy fields, small dunes, and stunning sunrises and sunsets.`);
        setslide(Slide1)
    }
    const handleprevClick = () => {
        setf2b1b1head2("Thrill of Off-Road Jeep Safari")
        setf2b1b1desc(`The jeep safari is a thrilling adventure in the Thar Desert. Whether you choose to enjoy the sunrise or sunset safari, you'll witness breathtaking desert landscapes. Some tours also include a visit to traditional mud houses. Our 4x4 jeep safari offers a 35-minute ride across the dunes.`);
        setslide(Slide2)
    }
    return (
        <>
            <div id="abframe2">
                <div id="abf2b1">
                    <div className="boxheads1">Experience the</div>
                    <div className='boxheads2'>{f2b1b1head2}</div>
                    <div id="abf2img"><img src={slide} alt="" id='slideabf'/></div>
                </div>
                <div id="abf2b2">
                    <div className="boxheadsdesc">{f2b1b1desc}</div>
                    <div id="forbtnflex">
                        <div id="f2b2sliderbtnprev" className="f2b2sliderbtn" onClick={handleprevClick}>&larr;</div>
                        <div id="f2b2sliderbtnnext" className="f2b2sliderbtn"onClick={handlenextClick}>&rarr;</div>
                    </div>

                </div>
            </div>
        </>
    )
}
