import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import './Admin.css';
import logo from './Images/logo.jpg';

export default function Navbar() {
    const navigate = useNavigate();

    // Logout function
    const handleLogout = () => {
        // Call the logout PHP file
        fetch('/backend/logout.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                // Redirect to the admin login page after successful logout
                navigate('/admin');
            } else {
                alert('Logout failed!');
            }
        })
        .catch(error => {
            console.error('Error during logout:', error);
        });
    };

    return (
        <>
            <div id="welcome">
                <Link className="btn btn-primary" data-bs-toggle="offcanvas" to="#offcanvasExample" role="" aria-controls="offcanvasExample" id='clear'>
                    <img src={logo} alt="Logo" id='logo' />
                    <div id="logohead">Desert Pearl Resort</div>
                </Link>
            </div>

            <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasExampleLabel">Welcome Admin</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link active" aria-current="page" to="/Dashboard">Dashboard</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/AddReviews">Add Reviews</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/upackages">Update Packages</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="">Upload Images</Link>
                        </li>
                        <li className="nav-item">
                            {/* Call handleLogout on click */}
                            <Link className="nav-link" to="#" onClick={handleLogout}>Logout</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}
