import React, { useState, useEffect } from 'react';
import './Main.css';

export default function Frame1() {
    const [packages, setPackages] = useState([]); // State to store fetched packages

    // Fetch packages from the server
    const fetchPackages = async () => {
        try {
            const response = await fetch('/backend/get_packages.php');
            const data = await response.json();
            
            if (data.success) {
                setPackages(data.packages); // Store the fetched packages in state
            } else {
                console.error(data.message);
            }
        } catch (error) {
            console.error("Error fetching packages:", error);
        }
    };

    // Fetch packages when the component loads
    useEffect(() => {
        fetchPackages();
    }, []);

    return (
        <>
            <div id="ppframe">
                {packages.map((pkg) => (
                    <div className="cardbox" key={pkg.package_id}>
                        <div className="cardhead2">{pkg.name}</div>
                        <div className="cardhead1 ppcardhead">{pkg.price}</div>
                        <div className="cardboxp2">
                            <img
                                src={`/backend/uploads/${pkg.image}`}
                                alt={pkg.name}
                                className="cardimg"
                            />
                            <div className="cardrest">
                                <div className="carddesc">{pkg.description}</div>
                                <div className="cardlists">
                                    <ul>
                                        <li>Traditional welcome with Aarti and Tikka.</li>
                                        <li>1-night stay at the camp.</li>
                                        <li>Evening camel safari on the dunes.</li>
                                        <li>Buffet dinner and snacks (vegetarian).</li>
                                        <li>Buffet breakfast (fixed menu).</li>
                                    </ul>
                                </div>
                                <button className='bookbtn'>Book Now</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}
