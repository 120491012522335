import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import './Admin.css';

export default function Frame1() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is logged in by calling the session check API
        fetch('/backend/check_session.php')
            .then((response) => response.json())
            .then((data) => {
                if (!data.loggedIn) {
                    // If not logged in, redirect to login page
                    navigate('/admin');
                } else {
                    setIsLoggedIn(true);
                }
            })
            .catch((error) => console.error('Error checking session:', error));
    }, [navigate]);

    // If the user is not logged in, don't render the dashboard
    if (!isLoggedIn) {
        return null;
    }

    return (
        <>
            <div id="dbf1">
                <div id="head2">Your Dashboard</div>
                <div className="grid-container">
                    <div className="grid-item">Name</div>
                    <div className="grid-item">Desert Pearl Resort</div>
                    <div className="grid-item">Your Email</div>
                    <div className="grid-item">muskan</div>
                    <div className="grid-item">Your Password</div>
                    <div className="grid-item">abc</div>
                    <div className="grid-item"><button type="button" className="btn btn-primary">Update Email ID</button></div>
                    <div className="grid-item"><button type="button" className="btn btn-primary">Change Password</button></div>
                </div>
            </div>
        </>
    );
}
