import './Main.css';



export default function Frame4() {
    const circles = document.getElementsByClassName("circles");
    const cards = document.getElementsByClassName("f4b3cards");
    const forcards=document.getElementById("forcards");
    
    const showcard = (event) => {
        const active = event.target;
        const activeId = active.id;
    
        // Extract the number from the id
        const number = activeId.match(/\d+/)[0];
    
        // Find the corresponding card and circle
        const selectedCard = document.getElementById('card' + number);
        const selectedCircle = document.getElementById('circle' + number);
    
        // Toggle card visibility and circle display using classes
        if (selectedCard.classList.contains('show')) {
            // If the card is visible, hide it and show the circle
            selectedCard.classList.remove('show');
            selectedCircle.classList.remove('hidden');
        } else {
            // Hide all cards
            for (let i = 0; i < cards.length; i++) {
                cards[i].classList.remove('show');
            }
            
            // Hide all circles
            for (let i = 0; i < circles.length; i++) {
                circles[i].classList.remove('hidden');
            }
    
            // Show the selected card and hide the circle

            forcards.classList.add('show');
            selectedCard.classList.add('show');
            selectedCircle.classList.add('hidden');
        }
    };
    
    // Attach the event listener to each circle
    for (let i = 0; i < circles.length; i++) {
        circles[i].addEventListener('click', showcard);
    }
    
    // Attach the event listener to each card for closing it and showing the circle again
    for (let i = 0; i < cards.length; i++) {
        cards[i].addEventListener('click', showcard);
    }
    
    return (
        <>
            <div id="frame4">
                <div id="f4b1">
                    <div id="f4b1b1">
                        <div className="boxheads1">See</div>
                        <div className='boxheads2'>What Sets Us Apart</div>
                        <div className="boxheadsdesc">Discover What Makes Our Resort Unique</div>
                    </div>
                    <div id="f4b1b2">
                        <div className="f4b1b2cards"><div className="cardhead1">Total</div><div className="cardhead2">50 Camps</div></div>
                        <div className="f4b1b2cards" id='f4b1b2cardparts'>
                            <div className="f4b1b2cardparts"><div className="cardhead1">Total Area</div><div className="cardhead2">4000 Sqft</div></div>
                            <div className="f4b1b2cardparts"><div className="cardhead1">Total</div><div className="cardhead2">3000 Visitors</div></div>
                        </div>
                        <div className="f4b1b2cards"><div className="cardhead1">Drive from city</div><div className="cardhead2">44 Km</div></div>
                    </div>
                </div>
                <div id="f4b2">
                    <div id="f4b2b1">
                        <div className="boxheads1">Explore</div>
                        <div className='boxheads2'>Camp Itinerary</div>
                    </div>
                    <div id="f4b2b2">
                        <div className="boxheadsdesc">Immerse Yourself in an Unforgettable Journey with Our Carefully Curated Itinerary, Packed with Exciting Activities, Scenic Exploration, and Memorable Experiences Awaiting You Each Day.</div>
                    </div>
                </div>
                <div id="f4b3">
                    <div id="forcards" className=''>
                        <div className="f4b3cards" id='card1'><div className="f4b3cardheads">Swiss Tent Accommodation</div><div className="f4b3carddesc">Experience a luxurious stay in our star-rated Swiss tents nestled in the heart of the Thar Desert, complete with all modern amenities.</div></div>
                        <div className="f4b3cards" id='card2'><div className="f4b3cardheads">Gaala Dinner</div><div className="f4b3carddesc">Savor authentic Rajasthani cuisine at our desert buffet, offering a true taste of tradition.</div></div>
                        <div className="f4b3cards" id='card3'><div className="f4b3cardheads">Cultural Program & DJ Party</div><div className="f4b3carddesc">Ignite your evening with live Rajasthani folk music and dances, followed by an electrifying DJ party.</div></div>
                        <div className="f4b3cards" id='card4'><div className="f4b3cardheads">Camel Safari</div><div className="f4b3carddesc">Delight in thrilling activities around Jaisalmer city and embark on unforgettable desert safari tours at Sam Sand Dunes.</div></div>
                    </div>
                    <div id="forblocks">
                        <div className="circles" id='circle1' onClick={showcard}></div>
                        <div className="circles" id='circle2' onClick={showcard}></div>
                        <div className="circles" id='circle3' onClick={showcard}></div>
                        <div className="circles" id='circle4' onClick={showcard}></div>
                    </div>
                </div>
            </div>
        </>
    )
}
