import React, { useState } from 'react';
import './Main.css';
import Slide1 from './Images/Slide1.png';
import Slide2 from './Images/Slide2.png';



export default function Frame1() {
    const [f2b1b1head2, setf2b1b1head2] = useState('A Full-Day Tour at Our Resort');
    const [f2b1b1desc, setf2b1b1desc] = useState('The tour kicks off with an exhilarating dune bashing adventure, followed by a range of enjoyable activities including camel rides, photo opportunities in traditional Rajasthani attire, and henna tattooing. As evening falls, relax in our desert camp, which mimics a Bedouin atmosphere, or gather around the fire until we serve a delicious BBQ dinner with a variety of salads, starters, and desserts. After a fantastic cultural show, the real highlight of the tour awaits: an overnight safari. We’ll provide you with excellent accommodations for the night in our desert camp, complete with sleeping bags, blankets, and unlimited tea and coffee.');
    const [slide, setslide] = useState(Slide2);
    const handlenextClick = () => {
        setf2b1b1head2("Premium Amenities at Our Camp")
        setf2b1b1desc("We offer fully equipped tent accommodations with private toilets and electricity, providing a home-away-from-home experience. Immerse yourself in nature and Rajasthani heritage in our elegantly designed tents, complete with a central room and verandah. Enhance your stay with our open-air restaurant and exclusive deluxe camel safaris and camping experiences throughout Rajasthan's desert. Our base camp, located 3 km before Sam Sand Dunes, features our special desert dream package, which includes an overnight stay, camel safari, traditional welcome, refreshments, folk music and dance, a campfire, dinner, and breakfast.");
        setslide(Slide1)
    }
    const handleprevClick = () => {
        setf2b1b1head2("A Full-Day Tour at Our Resort")
        setf2b1b1desc("The tour kicks off with an exhilarating dune bashing adventure, followed by a range of enjoyable activities including camel rides, photo opportunities in traditional Rajasthani attire, and henna tattooing. As evening falls, relax in our desert camp, which mimics a Bedouin atmosphere, or gather around the fire until we serve a delicious BBQ dinner with a variety of salads, starters, and desserts. After a fantastic cultural show, the real highlight of the tour awaits: an overnight safari. We’ll provide you with excellent accommodations for the night in our desert camp, complete with sleeping bags, blankets, and unlimited tea and coffee.");
        setslide(Slide2)
    }
    return (
        <>
            <div id="abframe2">
                <div id="abf2b1">
                    <div className="boxheads1">Experience</div>
                    <div className='boxheads2'>{f2b1b1head2}</div>
                    <div id="abf2img"><img src={slide} alt="" id='slideabf'/></div>
                </div>
                <div id="abf2b2">
                    <div className="boxheadsdesc">{f2b1b1desc}</div>
                    <div id="forbtnflex">
                        <div id="f2b2sliderbtnprev" className="f2b2sliderbtn" onClick={handleprevClick}>&larr;</div>
                        <div id="f2b2sliderbtnnext" className="f2b2sliderbtn"onClick={handlenextClick}>&rarr;</div>
                    </div>

                </div>
            </div>
        </>
    )
}
